import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    card: {
      height: 300,
      width: 300,
     // borderRadius: '50%',
      margin: '0 auto 2rem'
    },
    cardMedia: {
      height: 300
    },
    cardContent: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '90%',
      backgroundColor: '#ffffffcc',
      textAlign: 'center',
      '& h2': {
        fontSize: '1rem',
        textTransform: 'uppercase',
      },
      '& h3': {
        fontSize: '0.8rem',
        textTransform: 'uppercase',
      }
    },
    cardActions: {
      justifyContent: 'center'
    },
    actionButton: {
      width: 300,
      textTransform: 'uppercase',
    }
  })
});

export default useStyles