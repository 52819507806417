import React from "react"
import { Link } from "gatsby"
import useStyles from "./styles"
import { Grid, Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core'

const ProfileItem = ({ profile }) => {
  const classes = useStyles()

  const profileImage = profile?.img.childImageSharp.fluid.originalImg
  const name = profile?.name
  const subtitle = profile?.subtitle
  const id = profile?.id

  return (
    <Grid item xs={12} md={6} >
      <Link to={`/profile-item-page?id=${id}`}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia>
              <img src={profileImage} alt={name} title={subtitle} className={classes.cardMedia}/>
            </CardMedia>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h6" component="h2">
                {name}
              </Typography>
              <Typography gutterBottom variant="h6" component="h3">
                {subtitle}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>

  )

}

export default ProfileItem