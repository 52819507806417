import React from "react"
import { useQueryParam, StringParam } from "use-query-params"
import { useStaticQuery, Link, graphql } from "gatsby"
import { Grid, IconButton } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ProfileItem from '../components/ProfileItem'
import Layout from "../components/Layout"
import useStyles from '../styles/profileStyles'
import SEO from '../components/seo'

const ReactMarkdown = require('react-markdown')
const Profile = () => {
  const classes = useStyles()
  const query = useStaticQuery(graphql`
  {
    allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "team"}}}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            id
            title
            seoDescription
            seoTitle
            seoKeywords
            people {
              img {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }, 
              name
              subtitle
              id
              description
              seoDescription
              seoTitle
              seoKeywords
            }
          }
        }
      }
    }
  }
  `)
  const [id] = useQueryParam("id", StringParam);
  const profiles = query.allFile.nodes[0].childMarkdownRemark.frontmatter.people
  const profile = profiles.find((profile) => profile.id === id)
  const description = profile?.description
  const pageTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.title
  const seoTitle = profile?.seoTitle
  const seoDescription = profile?.seoDescription
  const seoKeywords = profile?.seoKeywords

  return (
    <Layout>
      <SEO title={seoTitle !== undefined ? seoTitle : pageTitle} description={seoDescription !== undefined ? seoDescription : null} keywords={seoKeywords !== undefined ? seoKeywords : null}/>
      <Link to='/team'>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <ArrowBackIosIcon />
        </IconButton>
      </Link>
      <Grid container justify="center" alignItems='center' direction='column'>
        <ProfileItem profile={profile} />
        <div className={classes.content}>
          <ReactMarkdown source={description} />
        </div>
      </Grid>
    </Layout>
  )
}

export default Profile
