import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
  return ({
    content: {
      textAlign: "center",
      '& h1': {
        color: theme.palette.primary.main,
        fontSize: '2rem',
      },
      '& h2': {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        textTransform: 'uppercase',
      },
      '& h3': {
        color: theme.palette.primary.main,
        fontSize: '1rem',
        
      },
      '& li': {
        textAlign: "left",
        fontSize: '1.5rem',
        lineHeight: '2rem',
        '& em': {
          color: theme.palette.primary.main,
          fontWeight: '700',
        }
      }
    }
  })
})

export default useStyles